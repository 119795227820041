<template>
  <div>
    <b-row>
      <!-- <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Mã kiểm định<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required"
            :custom-messages="code"
          >
            <b-form-input
              v-model="dataInput.Code"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Mã kiểm định<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="Code"
            rules="required"
            :custom-messages="code"
          >
            <b-form-input
              v-model="dataInput.Code"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="code"
        >
          <label for="code">Mô tả<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required"
            :custom-messages="description"
          >
            <b-form-input
              v-model="dataInput.description"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label for="code">Tổ chức kiểm định<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name=""
            rules="required"
            :custom-messages="bussiness"
          >
            <v-select
              v-model="dataInput.bussinessId"
              :reduce="item => item.id"
              :state="errors.length > 0 ? false:null"
              label="name"
              :options="listBussiness || []"
              placeholder="Lựa chọn doanh nghiệp được kiểm định"
              @input="getDataInspector"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label for="code">Kiểm định viên<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="type_accident"
            rules="required"
            :custom-messages="inspector"
          >
            <v-select
              v-model="dataInput.inspectorId"
              :reduce="item => item.id"
              :state="errors.length > 0 ? false:null"
              label="name"
              :options="listInspector || []"
              placeholder="Lựa chọn kiểm định viên"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Ngày kiểm định<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="DateDeclare"
            rules="required"
            :custom-messages="DateDeclare"
          >
            <date-time-picker
              v-model="dataInput.dateAccreditation"
              :disabledInput="true"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="File đính kèm"
          label-for="date"
        >
          <div class="d-flex">
            <b-form-input
              v-model="dataInput.fileName"
              :disabled="true"
            />

            <input
              ref="upload-file"
              type="file"
              class="d-none"
              @change="importFileExcel"
            />
            <b-button
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="uploadFile"
            >
              <feather-icon
                icon="UploadIcon"
                class="text-primary"
              />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},

    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Mã kiểm định là bắt buộc',
      },
      UserDeclare: {
        required: 'Tên người khai báo là bắt buộc',
      },
      description: {
        required: 'Mô tả là bắt buộc',
      },
      inspector: {
        required: 'Chọn kiểm định viên là bắt buộc',
      },
      DateDeclare: {
        required: 'Ngày kiểm định là bắt buộc',
      },
      bussiness: {
        required: 'Doanh nghiệp kiểm định là bắt buộc',
      },
      listInspector: [],
      listBussiness: [],
    }
  },
  watch: {
    dataInput(val) {
      if (val.bussinessId) {
        this.getListUrlInspector(val.bussinessId)
      }
    },
  },
  created() {
    this.getListUrlBussiness()
  },
  methods: {
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
    getDataInspector(val) {
      if (val) {
        this.getListUrlInspector(val)
      } else if (!val) {
        this.dataInput.inspectorId = ''
      }
    },
    async getListUrlInspector(bussinessId) {
      const res = await axiosApiInstance.get(ConstantsApi.COMBOBOX_INSPECTOR, {
        params: { bussinessId },
      })
      this.listInspector = res?.data?.data
    },
    async getListUrlBussiness() {
      const res = await axiosApiInstance.get(ConstantsApi.COMBOBOX_BUSSINESS)
      this.listBussiness = res?.data?.data
    },
  },
}
</script>
