var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":_vm.id,"cancel-variant":"outline-primary","ok-title":"Lưu","cancel-title":"Hủy bỏ","centered":"","no-close-on-backdrop":"","title":_vm.title},on:{"ok":function($event){return _vm.accept($event)},"hidden":_vm.resetData}},[_c('validation-observer',{ref:"rule"},[_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Thiết bị"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"equipment","rules":"required","custom-messages":_vm.equipment},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.id; },"state":errors.length > 0 ? false:null,"label":"name","options":_vm.listEquipment || [],"placeholder":"Lựa chọn thiết bị"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.equipmentId),callback:function ($$v) {_vm.$set(_vm.dataInput, "equipmentId", $$v)},expression:"dataInput.equipmentId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Số lượng"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.quantityAccreditation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.quantityAccreditation),callback:function ($$v) {_vm.$set(_vm.dataInput, "quantityAccreditation", $$v)},expression:"dataInput.quantityAccreditation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"code"}},[_vm._v("Loại kiểm định"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"accreditation","rules":"required","custom-messages":_vm.accreditation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.en; },"state":errors.length > 0 ? false:null,"label":"vi","options":_vm.array || [],"placeholder":"Lựa chọn loại kiểm định"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.accreditationType),callback:function ($$v) {_vm.$set(_vm.dataInput, "accreditationType", $$v)},expression:"dataInput.accreditationType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.dataInput.isPass),callback:function ($$v) {_vm.$set(_vm.dataInput, "isPass", $$v)},expression:"dataInput.isPass"}},[_vm._v(" Đạt kiểm định ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }