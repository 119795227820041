export default {
  // Kiểm định thiết bị
  API_ACCREDITATION: 'Accreditation/accreditations',
  CREATE_ACCREDITATION: 'Accreditation/accreditations',
  EDIT_ACCREDITATION: 'Accreditation/accreditations',
  DELETE_ACCREDITATION: 'Accreditation/delete-accreditations',
  DETAIL_ACCREDITATION: 'Accreditation/accreditations/',
  LIST_EQUIPMENT_ACCREDITATION: 'Accreditation/list-accreditation-equipment',
  CREATE_EQUIPMENT_ACCREDITATION: 'Accreditation/add-accreditation-equipment',
  DELETE_EQUIPMENT_ACCREDITATION: 'Accreditation/delete-accreditation-equipment',
  COMBOBOX_INSPECTOR: 'Inspector/get-combobox-inspector-by-bussiness',
  COMBOBOX_BUSSINESS: 'Bussiness/get-combobox-bussiness',
  COMBOBOX_EQUIPMENT: 'Equipment/get-combobox-equipment',
}
